import MismatchItem from 'src/components/FullPatientData/PatientWidgetBody/MismatchItem/MismatchItem';

export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  components: {
    MismatchItem,
  },
};
